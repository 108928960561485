import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/Cyber/2023/senior/1.jpg';
// import p2 from 'assests/Photos/Clubs/Cyber/2023/senior/2.jpg';
// import p3 from 'assests/Photos/Clubs/Cyber/2023/senior/3.jpg';
// import p4 from 'assests/Photos/Clubs/Cyber/2023/senior/4.jpg';
// import p5 from 'assests/Photos/Clubs/Cyber/2023/senior/5.jpg';
// import p6 from 'assests/Photos/Clubs/Cyber/2023/senior/6.jpg';
// import p7 from 'assests/Photos/Clubs/Cyber/2023/senior/7.jpg';
// import p8 from 'assests/Photos/Clubs/Cyber/2023/senior/8.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import Archive2022 from 'views/CyberClub/components/Archive2022';
import { Sidebar2023 } from './component';


const CyberThreats2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Cyber/Cyber-senior/1.JPG`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Cyber/Cyber-senior/2.JPG`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Cyber/Cyber-senior/3.JPG`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Cyber/Cyber-senior/4.JPG`;
  const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Cyber/Cyber-senior/5.JPG`;
  const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Cyber/Cyber-senior/6.JPG`;
  const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Cyber/Cyber-senior/7.JPG`;
  const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Cyber/Cyber-senior/8.JPG`;

  const photos = [
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 3,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 3,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 2,
    },
    
      {
          src: p6,
          source: p6,
          rows: 1,
          cols: 1,
        },
        {
          src: p5,
          source: p5,
          rows: 1,
          cols: 2,
        },
        {
          src: p1,
          source: p1,
          rows: 1,
          cols: 1,
        },
        {
          src: p7,
          source: p7,
          rows: 1.5,
          cols: 3,
        },
        {
          src: p8,
          source: p8,
          rows: 1.5,
          cols: 3,
        },
        
        
  


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      ‘CYBER THREATS AND SECURITY’
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
     Class: 9 to 12  Date: 7 OCTOBER 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Senior Cyber association organized “Cyber Threats And Security” an inter house event on Saturday, 7 October 2023 to give awareness on Cyber Threats and Security. The first round was a Questionnaire related to electronic information security. The final round was a skit, where the teams competed to raise awareness on different Cyber Attacks. Students participated enthusiastically and exhibited their teamwork, leadership, creativity and critical thinking skills. Students thoroughly enjoyed the activity and a better learning from each other.
        <br></br>
        </Typography>
      </Box>
      <Typography
          variant={'h5'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
     “A data breach is about both privacy and security”
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>

             <Box marginBottom={4}>
               <Sidebar2023 />
             </Box> 

             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default CyberThreats2023;